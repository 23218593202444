import React, { useEffect } from 'react';

function ArticlePage09() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <div className="xContent p-box py-6">
        <div className='xContent-wrapper'>
        <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">Международный фестиваль «Москва встречает друзей»</h1>
        <div className="content">
        <p>C 25 ноября по 3 декабря 2019 года, в год 25-летия Международного благотворительного фонда Владимира Спивакова, при участии ЮНЕСКО и Европейской ассоциации международных юношеских конкурсов (EMCY) в Москве пройдет XVI Международный фестиваль «Москва встречает друзей» — самый масштабный проект.</p>
        <p>Фестиваль создан и организован фондом Владимира Спивакова при участии Правительства Москвы и Департамента культуры г. Москвы, Администрации Президента РФ, Министерства иностранных дел, Организации Объединенных Наций, Комиссии Российской Федерации по делам ЮНЕСКО. В состав оргкомитета фестиваля входят видные политики, общественные деятели, ученые, представители культуры и искусства.</p>
        <p>Ежегодно с 2004 года посланники из разных регионов России, СНГ, стран Балтии и дальнего зарубежья – солисты-инструменталисты, вокалисты, танцевальные и хоровые коллективы, цирковые студии, художники, оркестры и дирижеры – представляют свое искусство на лучших концертных площадках Москвы и Подмосковья, а также других городов России.</p>
        <p>В этом году более тысячи талантливых детей из 50 регионов России и 36 стран мира примут участие в концертах, которые будут проходить каждый день на разных сценах — в Светлановском и Камерном залах Московского международного Дома музыки, Государственном музее А.С. Пушкина, Московской государственной картинной галерее народного художника СССР А. Шилова, школе акварели Сергея Андрияки, Музее-заповеднике Коломенское, в Центре временного содержания несовершеннолетних правонарушителей и на многих других площадках.</p>
        <p>Открытие XVI Международного фестиваля «Москва встречает друзей» состоится 25 ноября на сцене Светлановского зала Московского международного Дома музыки. В концерте открытия примет участие Национальный филармонический оркестр России под управлением Владимира Спивакова. В этот день Владимир Спиваков вручит приз «Милосердие» Международного благотворительного фонда Владимира Спивакова Юджину Чаплину, сыну великого Чарли Чаплина.</p>
        <p>Масштабная программа продолжится и на других концертных и выставочных площадках Москвы и Московской области. В рамках фестиваля будут представлены художественные работы более 230 юных художников — участников фестиваля из 8 стран мира и регионов России.</p>
        <p>В дни фестиваля по сложившейся доброй традиции стипендиаты фонда Владимира Спивакова получат в дар музыкальные инструменты, а также аксессуары, столь необходимые юным музыкантам.</p>
        <p>Завершится XVI Международный фестиваль «Москва встречает друзей» торжественным концертом в Камерном зале Московского международного Дома музыки 3 декабря.</p>

        </div>
      </div>
      </div>
    );
  }

  export default ArticlePage09;
