import ArticlePage18Img from '../../images/articlepage18.png'
import ArrowImg from '../../images/arrow.svg'
import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';

function ArticlePage18() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <div className="xContent p-box py-6">
        <Helmet>
        <title>Финал Международного конкурса творческих работ звукорежиссёров 2018</title>
        <meta
        name="description"
        content='31 марта состоялся финал Международного конкурса творческих работ звукорежиссёров 2018'
        />
        <link rel="canonical" href="https://arts-association.com/article18"/>
      </Helmet>

      <div className='xContent-wrapper'>
      <div className="content p-20">
      <p className='has-text-left wysiwyg'>2 апреля 2018 год</p>
        <div className="title p-1">31 марта состоялся финал Международного конкурса творческих работ звукорежиссёров 2018</div>
          <div class="block">
    <div class="articleImage700"><img src={ArticlePage18Img} alt="Order" /></div>
    </div>

        <p>Международный конкурс творческих работ звукорежиссёров успешно завершил свой цикл 2018 года, отметившись невероятной конгломерацией талантов – 326 специалистов, охвативших географию от России до Грузии, проявили свои навыки в искусстве звукового оформления. Конкурс, пройдя в онлайн-режиме, предоставил уникальную площадку для креативного взаимодействия, выходящего за рамки обыденного.</p>
        <p>В роли судей выступили неоспоримые авторитеты в лице Евгения Доги, Фарруха Замонова, Ерлана Бекчурина, Владимира Опрышко, Рустама Орипова и Фархада Фарзалиева, чьи имена являются синонимами международного уровня мастерства и профессионализма в аудиопроизводстве.</p>
        <p>Инновационный подход к судейству, основанный на принципах честности и прозрачности, подчеркивает не только качество поданных на конкурс работ, но и глубокую отдачу каждого участника в своём творческом вкладе.</p>
        <p>Этот фестиваль звукорежиссуры олицетворял собой не просто соревнование, но и центр обмена инновациями, демонстрируя масштаб влияния и важность аудиального искусства на международном уровне.</p>
        <p>Конкурс проводился среди профессиональных звукорежиссеров с опытом работы не менее 2-х лет в номинациях:  "Академическая музыка" и "Музыка неакадемических стилей».</p>
        <p>Победителями в этих категориях стали:</p>
        <ul className="content-details-list">
          <li>  <span class="arrow-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>
            <span className="content-text">Академическая музыка: Андрей Пригожин (Украина), Дмитрий Сидоров (Азербайджан) и Михаил Сидоров (Украина).</span></li>
            <li>  <span class="arrow-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>
            <span className="content-text">Музыка неакадемических стилей: Александр Курасов (Россия), Максим Дмитриев (Узбекистан) и Дмитрий Петров (Украина).</span></li>
      </ul>
      <p>Каждый из победителей не просто заявил о себе, но и унёс в свою страну частицу международного признания, что бесспорно станет толчком к дальнейшему профессиональному росту и творческим поискам.</p>
      <p>Организаторы искренне поздравляют победителей и участников с заслуженными успехами и выражают благодарность за их неоценимый вклад в арт-индустрию. Теперь мы в предвкушении следующего события, где новые и уже знакомые имена снова соберутся, чтобы превзойти самих себя и продолжить традиции международного аудиального братства.</p>
        <p className='has-text-right wysiwyg p-1'><b>Материал подготовил Святослав Овчинников</b></p>
        </div>
        </div>
      </div>
    );
  }

  export default ArticlePage18;
