import React, { useEffect } from 'react';

function ArticlePage05() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="xContent p-box py-6">
      <div className='xContent-wrapper'>
      <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">Шедевры Баха в джазовой интерпретации прозвучат в Калининграде</h1>
      <div className="content">
      <p>Произведения великого немецкого композитора Иоганна Себастьяна Баха смогут услышать зрители Международного музыкального фестиваля «Бахослужение», который в 21-й раз пройдет в Калининграде с 22 по 28 июля.</p>
      <p>«Своеобразным открытием для многих поклонников творчества Баха станет исполнение на фестивале академической органной музыки в джазовой интерпретации», — сообщает пресс-служба фестиваля.</p>
      <p>Один из наиболее востребованных российских органистов, победитель многих международных конкурсов Константин Волостнов представит программу под названием «Вечер органной сонаты», в рамках которой исполнит шедевры Баха и его соотечественников Феликса Мендельсона, Августа Риттера, Юлиуса Ройбке. Выдающийся российский скрипач-виртуоз, солист Московской филармонии Павел Милюков в сопровождении камерного оркестра Калининградской филармонии представит два концерта Баха — для скрипки с оркестром и для скрипки, струнных и клавесина.</p>
      <p>В день открытия фестиваля программу «Энигма Баха и русской души» исполнит дуэт органистов из Японии и Нидерландов, лауреаты международных конкурсов Хироко Иноуэ и Жан-Пьер Стайверс. В их исполнении, в четыре руки, прозвучат произведения Баха, а также русских композиторов Сергея Рахманинова и Игоря Стравинского.</p>
      <p>Традиционно в фестивале активно участвуют солисты, а также струнный квартет областной филармонии. В их исполнении на это раз прозвучат не только известные сочинения Баха, но и редкие сочинения основоположников барочной музыки; также впервые в стенах исторического здания филармонии будут исполнены «Свадебная кантата» Баха и Концерт для органа № 9 Георга Генделя.</p>
      <p>Фестиваль «Бахослужение» проходит в Калининградской филармонии с 2000 года, в здании которого 40 лет назад был установлен уникальный акустический орган на 3600 труб. В 2022 году мероприятие организовано при поддержке Министерства культуры РФ (программа «Всероссийские филармонические сезоны») и Министерства по культуре и туризму Калининградской области.</p>



      </div>
      </div>
    </div>
  );
}

export default ArticlePage05;
