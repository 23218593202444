import React, { useEffect } from 'react';

function ArticlePage03() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="xContent p-box py-6">
      <div className='xContent-wrapper'>
      <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">Как помочь музыкальной индустрии?</h1>
      <div className="content">
      <p>«Организации музыкальной индустрии несут колоссальные убытки и ощущают нехватку средств для осуществления своей хозяйственной деятельности. При этом основной удар пришелся на сегмент малого и среднего бизнеса, который не имеет каких-либо ресурсов для стабилизации ситуации», – говорилось в письме, подписанном председателем Комитета РСПП по интеллектуальной собственности и креативным индустриям Андреем Кричевским, композитором Эдуардом Артемьевым, альтистом и дирижером Юрием Башметом и председателем правления Национальной федерации производителей фонограмм Александром Блиновым.</p>
      <p>Пока общественность ожидает ответной реакции, мы попросили разъяснения у Андрея Кричевского, насколько эффективны могут быть предложенные меры. «Нами была проведена серия рабочих встреч с представителями музыкальной индустрии, и коллегиально были выработаны меры по поддержке сферы, которые на наш взгляд могли бы стать наиболее действенными в сложившейся ситуации. Выработанные предложения и легли в основу обращения к главе Правительства Российской Федерации.</p>
      <p>Все наши предложения связаны с поддержкой индустрии в опорных моментах и касаются только законодательного регулирования данной отрасли. Чтобы помочь музыкальной индустрии, правительству не нужно тратить бюджетные средства – достаточно точечных регуляторных новаций и стабилизации налогового режима. Именно этот тезис звучит в письме.</p>
      <p>Изменение регулирования музыкальной индустрии назрело уже давно. Для нас очевидно, что в текущей модели распределения прибыли между дистрибьюторами (цифровыми площадками) и создателями контента доходы от цифровых сервисов лишь в небольшой степени позволяют заместить доходы от использования музыки в офлайн-пространстве. Если будет решена проблема несправедливого распределения таких доходов в цепочке дистрибуции, то вырастут доходы всех создателей контента и, как следствие, – тех, кто задействован в процессе его создания. Именно поэтому необходимы законодательные изменения, описанные в письме», – прокомментировал А.Б. Кричевский.</p>
      <p>Предложены, напомним, следующие шаги: отказ от взимания НДС при сделках с авторскими и смежными правами – это позволит увеличить объем денег, остающихся в индустрии; отказ от взимания страховых взносов (возможно, на определенный период), что позволит уже сейчас направить больше средств создателям контента. Также, по мнению авторов письма, требуются законодательные изменения, направленные на установление четкого механизма выплат цифровыми сервисами авторских вознаграждений. И еще — включение музыкальной индустрии в список сфер деятельности, наиболее пострадавших от пандемии: это даст доступ участникам индустрии к мерам государственной поддержки, которые системно реализуются для пострадавших сфер деятельности.</p>
      <p>«Оказав содействие основополагающим участникам музыкальной индустрии ─ тем столпам, на которых и благодаря которым данная индустрия развивается как сектор экономики, ─ мы автоматически создаем предпосылки для укрепления всей индустрии на всех ее уровнях, вследствие чего каждый ее участник сможет не только выстоять в кризис, но и получить новые возможности», ─ считает Кричевский.</p>


      </div>
      </div>
    </div>
  );
}

export default ArticlePage03;
