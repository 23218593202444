import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function EthicsCodex() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <Helmet>
        <title>Этический кодекс</title>
        <meta
        name="description"
        content="Этический кодекс члена Международной Ассоциации Деятелей Искусств"
        />
        <link rel="canonical" href="https://arts-association.com/ethicscodex" />
      </Helmet>
      <div className="ethics-content">
        <div className="content-blur"></div>
        <div className="content-overlay">
          <h1 className="main-title">Этический кодекс члена</h1>
          <h1 className="main-title">Международной Ассоциации Деятелей Искусств</h1>
          <h2 className="main-subtitle">Мы формируем культуру будущего уже сегодня!</h2>
        </div>
      </div>
      <div className='xContent p-box py-6'>
      <div className='xContent-wrapper'>
      <div className="page-title">ЭТИЧЕСКИЕ ПРИНЦИПЫ ДЕЯТЕЛЬНОСТИ</div>
        <div className="content p-20">
        <div className="title">Членов Международной Ассоциации Деятелей Искусств</div>
        <p><b>Честность и Интегритет.</b> Члены Ассоциации придерживаются высочайших стандартов правдивости и объективности в своей профессиональной деятельности. Они убеждены, что любая недостоверность или предвзятость подрывает доверие к специалисту и к организации в целом.</p>
        <p><b>Прозрачность.</b> Ассоциация приветствует активное участие членов в обмене идей и информацией, а также в разработке и продвижении профессиональных новшеств. Работы и достижения, официально одобренные Ассоциацией, становятся доступными для публичного ознакомления.</p>
        <p><b>Сообщество и Поддержка.</b> Члены Ассоциации активно вовлечены в реализацию целей и проектов организации. Они поощряют рост и улучшение имиджа Ассоциации, а также выступают в защиту ее интересов в случае необоснованных критических атак.</p>
        <p><b>Взаимопомощь.</b> Члены Ассоциации оказывают друг другу помощь и поддержку в профессиональных вопросах, стремясь отвечать на запросы коллег в разумные сроки.</p>
        <p><b>Обязательства и Обязанности.</b> Взятые на себя обязательства перед Ассоциацией должны быть выполнены в срок и с должным качеством. В случае невозможности выполнения необходимо обеспечить надлежащую замену и уведомить об этом руководство Ассоциации.</p>
        <p><b>Уважение и Толерантность.</b> Члены Ассоциации уважают многообразие и открыты для различных мнений, при этом придерживаются законности и этических стандартов, поддерживая профессиональное развитие.</p>
        <p><b>Конструктивная Критика.</b> Члены Ассоциации имеют право выражать обоснованную и конструктивную критику в рамках Ассоциации, улучшая тем самым общую деятельность.</p>
        <p><b>Ответственность.</b> Любые нарушения этических норм, подтвержденные документально или свидетельствами, подлежат рассмотрению руководящими органами Ассоциации и могут привести к исключению из членов Ассоциации с последующим информированием общественности.</p>
        </div>
        </div>
        </div>
    </div>
  );
}

export default EthicsCodex;
