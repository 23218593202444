import React, { useEffect } from 'react';

function ArticlePage06() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="xContent p-box py-6">
      <div className='xContent-wrapper'>
      <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">В Москве пройдет фестиваль забытых в советское время композиторов</h1>
      <div className="content">
      <p>13 и 14 февраля 2020 года в московском театре «Новая опера» состоится Международный музыкальный фестиваль «Энергия открытий», посвященный творчеству русских композиторов, забытых в советскую эпоху.</p>
      <p>Как рассказала Ирина Герасимова, генеральный директор радио «Орфей», которое выступает соорганизатором мероприятия, на сегодняшний день в архивах хранится огромное количество потрясающих произведений, которые не нашли отклика при жизни талантливых русских композиторов.</p>
      <p>«Они не соответствовали требованиям советской пропаганды, не исполнялись, а в итоге были забыты. Наша миссия – открыть эту музыку современникам, не дать кануть в лету», – сказала Ирина Герасимова.</p>
      <p>Проект является результатом пяти лет работы с архивами, восстановления нот, создания студийных записей и документальных фильмов. В рамках фестиваля будет представлена музыка Леонида Половинкина (1894-1949), Георгия Катуара (1861-1926) и Николая Голованова (1891-1953) в исполнении симфонического оркестра радио «Орфей» под управлением Сергея Кондрашева.</p>
      <p>Программа также включает показ двух музыкальных спектаклей: постановки «Корабль счастье» на музыку Половинкина, отсылающей к одному из самых счастливых периодов жизни композитора – творческому роману с Наталией Сац, и спектакля «7 нельзя», повествующего об историях любви известных людей – Отто фон Бисмарка, Дмитрия Шостаковича, Сергея Эйнштейна и других.</p>
      </div>
      </div>
    </div>
  );
}

export default ArticlePage06;
