import React, { useEffect } from 'react';

function ArticlePage07() {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="xContent p-box py-6">
      <div className='xContent-wrapper'>
      <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">В Московской консерватории состоится концерт «Московского трио»</h1>
      <div className="content">
      <p>2 ноября в Малом зале Московской государственной консерватории имени П. И. Чайковского состоится концерт знаменитого музыкального коллектива «Московское трио».</p>
      <p>В 2016 году «Книга рекордов России» зафиксировала абсолютный рекорд творческого долголетия ансамбля – свыше 40 лет в неизменном составе: c 1976 года в коллективе играют член Совета Российского музыкального союза Михаил Уткин (виолончель), Александр Бондурянский (фортепиано), Владимир Иванов (скрипка).</p>
      <p>В ходе предстоящего концерта музыканты исполнят трио № 3 соль минор и пьесы-фантазии Роберта Шумана и трио № 2 до минор Феликса Мендельсона.</p>
      <p>Михаил Уткин ­­– музыкант-виолончелист, педагог, музыкальный деятель, народный артист России. В 1978–1988 годах преподавал класс виолончели в Московской консерватории. Проводил мастер-классы в России, Германии, Финляндии, Чехии, Италии, США.</p>
      <p>В 2010–2014 годы был художественным руководителем Концертного филармонического объединения «Москонцерт». Член Германского общества Д. Д. Шостаковича (с 2006 года), Авторского союза Чехии (с 2010 года).</p>
      <p>Автор более трехсот аранжировок классических и современных произведений, вошедших в концертный и учебно-педагогический репертуар, изданных в Мюнхене, Москве и Санкт-Петербурге и записанных на компакт-дисках. В репертуаре – более тысячи произведений, в том числе около 200 фортепианных трио, 120 виолончельных сонат и концертов. Записал 15 грампластинок и 32 компакт-диска в России, Германии, Франции и США.</p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>

      </div>
      </div>
    </div>
  );
}

export default ArticlePage07;
