import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function Statute() {

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);
    const section6Ref = useRef(null);
    const section7Ref = useRef(null);
    const section8Ref = useRef(null);
    const section9Ref = useRef(null);
    const section10Ref = useRef(null);
    const section11Ref = useRef(null);
    const section12Ref = useRef(null);
    const section13Ref = useRef(null);
    const section14Ref = useRef(null);

    const scrollToSection = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);


    return (
      <div className="xContent p-box py-6">
        <Helmet>
        <title>Устав</title>
        <meta
        name="description"
        content='Устав Международной Ассоциации Деятелей Искусств'
        />
        <link rel="canonical" href="https://arts-association.com/statute"/>
      </Helmet>
        <div className="title p-60">
        УСТАВ Международной Ассоциации Деятелей Искусств
        </div>
        <div className='xContent-wrapper'>
        <div className="content p-20">
      <p className="is-italic has-text-left">Утверждён Собранием учредителей</p>
      <p className="has-text-left">Международной Ассоциации Деятелей Искусств </p>
      <p className="has-text-left">Протокол № 1 от 14 января 2014 г.</p>


        <h2 className="title is-font-title-regular has-text-centered has-text-var-blue">УСТАВ</h2>
        <p className="has-text-centered">Международной Ассоциации Деятелей Искусств</p>
        <p className="has-text-centered is-italic">г. Москва, 2014 год</p>


      <section className="tableOfContents">
        <div className="statute-subtitle">Содержание:</div>
        <ul className="statuteList">
          <li onClick={() => scrollToSection(section1Ref)}><div className="li_style">Общие положения</div></li>
          <li onClick={() => scrollToSection(section2Ref)}><div className="li_style">Цели, предмет, виды деятельности</div></li>
          <li onClick={() => scrollToSection(section3Ref)}><div className="li_style">Права и обязанности</div></li>
          <li onClick={() => scrollToSection(section4Ref)}><div className="li_style">Членство в ассоциации</div></li>
          <li onClick={() => scrollToSection(section5Ref)}><div className="li_style">Управление ассоциацией</div></li>
          <li onClick={() => scrollToSection(section6Ref)}><div className="li_style">Общее собрание членов ассоциации</div></li>
          <li onClick={() => scrollToSection(section7Ref)}><div className="li_style">Правление ассоциации</div></li>
          <li onClick={() => scrollToSection(section8Ref)}><div className="li_style">Председатель правления ассоциации</div></li>
          <li onClick={() => scrollToSection(section9Ref)}><div className="li_style">Контрольно-ревизионный орган ассоциации</div></li>
          <li onClick={() => scrollToSection(section10Ref)}><div className="li_style">Учет и отчетность ассоциации</div></li>
          <li onClick={() => scrollToSection(section11Ref)}><div className="li_style">Порядок внесения изменений и дополнений в устав</div></li>
          <li onClick={() => scrollToSection(section12Ref)}><div className="li_style">Совет Ассоциации</div></li>
          <li onClick={() => scrollToSection(section13Ref)}><div className="li_style">Внешнеэкономическая	деятельность	и	международные связи ассоциации</div></li>
          <li onClick={() => scrollToSection(section14Ref)}><div className="li_style">Реорганизация и ликвидация ассоциации</div></li>
        </ul>
      </section>
      <section id="1" ref={section1Ref}>
        <div className="title is-font-title-regular has-text-left has-text-var-blue pt-5">1. ОБЩИЕ ПОЛОЖЕНИЯ</div>
        <p>1.1. Международная ассоциация деятелей искусств, (далее Ассоциация), является основанной на членстве некоммерческой организацией, не преследующей извлечение прибыли в качестве основной цели своей деятельности и не распределяющей полученную прибыль между своими членами. Ассоциация является добровольным объединением деятелей искусств созданная ими для достижения уставных целей каждого, представления и защиты их общих интересов.</p>
        <p>1.2. В своей деятельности Ассоциация руководствуется Конституцией Российской Федерации, Федеральным законом «О некоммерческих организациях», Гражданским кодексом Российской Федерации, другими законами и правовыми актами Российской Федерации, настоящим Уставом.</p>
        <p>1.3. Наименование Ассоциации:
        Полное наименование – Международная ассоциация деятелей искусств. Краткое наименование – МАДИ
        Наименование Ассоциации для международных контактов: International Association of Arts Professionals.</p>
        <p>1.4. Ассоциация создается без ограничения срока ее действия.</p>
        <p>1.5.Место нахождения Ассоциации: Новослободская улица, 73, Москва, Россия, 127055 </p>
        <p>1.6. Организационно-правовая форма Ассоциации: Ассоциация.</p>
        <p>1.7. Территориальная сфера деятельности: Ассоциация объединяет представителей творческих профессий из России, Украины, Азербайджана, Армении, Беларуси, Казахстана, Киргизии, Молдовы, Таджикистана, Туркменистана, Узбекистана и Грузии.</p>
        <p>1.8. Ассоциация является юридическим лицом с момента ее государственной регистрации, имеет в собственности обособленное имущество, может быть истцом и ответчиком в судах общей юрисдикции, арбитражных и третейских судах, от своего имени приобретать и осуществлять имущественные и неимущественные права в соответствии с целями деятельности Ассоциации, предусмотренными Уставом Ассоциации.</p>
        <p>1.9. Ассоциация является некоммерческой организацией и не ставит целью извлечение прибыли. Получаемые из любых источников средства, в том числе доходы от хозяйственной деятельности Ассоциации, направляются на решение ее уставных целей, выполнение обязательств и оплату труда работников Ассоциации, и не распределяются между ее Учредителями.</p>
        <p>1.10.Ассоциация вправе заниматься предпринимательской деятельностью, необходимой для достижения целей, ради которых она создана, и соответствующей этим целям, создавая для осуществления предпринимательской деятельности хозяйственные общества или участвуя в них.</p>
        <p>1.11. Ассоциация имеет самостоятельный баланс, свои счета в банковских учреждениях (в том числе - валютные), печать, бланки, штампы, символику, иные реквизиты.</p>
        <p>1.12. Ассоциация самостоятельно определяет свою внутреннюю структуру, цели, формы и методы своей деятельности.</p>
        <p>1.13. Для достижения уставных целей, ради которых создана Ассоциация, она может создавать, учреждать хозяйственные общества для ведения предпринимательской деятельности, и участвовать в их деятельности, соответствующей этим целям.</p>
        <p>1.14. Ассоциация не отвечает по обязательствам своих членов. Члены Ассоциации несут субсидиарную ответственность по обязательствам Ассоциации пропорционально своему вкладу. Ассоциация отвечает по своим обязательствам тем своим имуществом, на которое по законодательству Российской Федерации может быть обращено взыскание.</p>
      </section>
      <section id="2" ref={section2Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">2. ЦЕЛЬ, ПРЕДМЕТ, ВИДЫ ДЕЯТЕЛЬНОСТИ</div>
        <p>2.1. Целью Ассоциации является просвещение населения в сфере искусств, а также представление и защита общих интересов входящих в Ассоциацию членов на основе использования интеллектуального потенциала, материальных и финансовых ресурсов всех заинтересованных сторон.</p>
        <p>2.2. Предметом деятельности Ассоциации является:</p>
        <ul className='goalsList'>
          <li>просвещение населения в сфере искусств путем организации и проведения концертов, спектаклей, выставок, съездов, конференций и иных мероприятий;</li>
          <li>представление и защита общих интересов членов Ассоциации;</li>
          <li>содействие в издательской деятельности;</li>
          <li>подготовка методических пособий и научных статей по вопросам организации деятельности в сфере искусств;</li>
          <li>содействие установлению контактов членов Ассоциации с деятелями искусств регионов Российской Федерации и зарубежных стран;</li>
          <li>содействие в подготовке кадров и специалистов в сфере искусств;</li>
          <li>оказание консультативных и организационных услуг в сфере искусств;</li>
          <li>взаимодействие по формированию в обществе понимания значимости искусств для нравственного воспитания и просвещения населения Российской Федерации;</li>
          <li>содействие по привлечению внимания государственных органов власти к проблемам сферы искусств;</li>
          <li>содействие в повышении профессиональной квалификации деятелей искусств  стран СНГ,</li>
        </ul>
        <p>2.3. Ассоциация вправе заниматься предпринимательской деятельностью, необходимой для достижения целей, ради которых она создана, и соответствующей этим целям, создавая для осуществления предпринимательской деятельности хозяйственные общества или участвуя в них.</p>
        <p>2.4. Ассоциация в пределах своей компетенции сотрудничает со всеми заинтересованными предприятиями, общественными и научными организациями, органами законодательной власти, зарубежными и международными организациями и иными юридическими и физическими лицами.</p>
        <p>2.5. Доходы от приносящей доход деятельности Ассоциации, указанной в пункте 2.3. Устава, не могут перераспределяться между членами Ассоциации, а используются только для достижения установленных целей.</p>
      </section>
      <section id="3" ref={section3Ref}>
      <div className="title is-font-title-regular has-text-left pt-6">3. ПРАВА И ОБЯЗАННОСТИ</div>
      <p>3.1. Для осуществления уставных целей Ассоциация имеет право:</p>
      <ul className='goalsList'>
        <li>представлять и защищать общие законные интересы ее членов;</li>
        <li>в установленном порядке открывать счета в банках на территории Российской Федерации и за пределами ее территории;</li>
        <li>иметь штампы и бланки со своим наименованием и логотипом который представляет из себя синюю полосу по левому краю с расположенным на ней столбцом текстом: «Российская ассоциация деятелей искусств»;</li>
        <li>преобразоваться в фонд, автономную некоммерческую организацию;</li>
        <li>участвовать в выработке решений органов государственной власти и органов местного самоуправления в порядке и объеме, предусмотренными законодательством Российской Федерации;</li>
        <li>свободно распространять информацию о своей деятельности;</li>
        <li>учреждать средства массовой информации и осуществлять издательскую деятельность;</li>
        <li>получать гранты на цели, предусмотренные уставом Ассоциации;</li>
        <li>осуществлять предпринимательскую деятельность лишь постольку, поскольку это служит достижению целей, ради которых она создана и соответствует указанным целям;</li>
        <li>создавать хозяйственные общества, участвовать в хозяйственных обществах (обществах с ограниченной ответственностью и акционерных обществах), приобретать имущество, предназначенное для ведения предпринимательской деятельности;</li>
        <li>самостоятельно распоряжаться доходами, полученными от предпринимательской деятельности, и приобретенным за счет этих доходов движимым и недвижимым имуществом в Российской Федерации и других государствах;</li>
        <li>аключать с Российскими и зарубежными предприятиями, научными организациями, учреждениями, обществами и иными юридическими и физическими лицами договоры и иные сделки в соответствии с действующим законодательством и не противоречащие целям деятельности Ассоциации;</li>
        <li>осуществлять наем лиц по трудовому договору либо по договору гражданско- правового характера в соответствии с законодательством Российской Федерации.</li>
      </ul>
      <p>3.2. Ассоциация имеет иные права, не противоречащие законодательству Российской Федерации.</p>
      <p>3.3. Ассоциация обязана:</p>
      <ul className='goalsList'>
        <li>соблюдать законодательство Российской Федерации, общепризнанные принципы и нормы международного права, касающиеся сферы ее деятельности, а также нормы, предусмотренные настоящим Уставом и иными учредительными документами;</li>
        <li>вести бухгалтерский учет и статистическую отчетность в порядке, установленном законодательством;</li>
        <li>предоставлять информацию о своей деятельности органам государственной статистики и налоговым органам, учредителям и иным лицам в соответствии с законодательством и учредительными документами Ассоциации;</li>
        <li>представлять в уполномоченный орган документы, содержащие отчет о своей деятельности, о персональном составе руководящих органов, а также документы о расходовании денежных средств и об использовании иного имущества, в том числе полученных от международных и иностранных организаций, иностранных граждан и лиц без гражданства по формам и в сроки представления указанных документов, которые определяются уполномоченным федеральным органом исполнительной власти;</li>
        <li>ежегодно размещать в информационно-телекоммуникационной сети «Интернет» или предоставлять средствам массовой информации для опубликования отчет о своей деятельности в объеме сведений, представляемых в уполномоченный орган или его территориальный орган;</li>
        <li>информировать уполномоченный орган об изменении сведений, указанных в пункте 1 статьи 5 Федерального закона «О государственной регистрации юридических лиц и индивидуальных предпринимателей», за исключением сведений о полученных лицензиях, в течение трех дней со дня наступления таких изменений и представлять соответствующие документы для принятия решения об их направлении в регистрирующий орган;</li>
        <li>нести другие обязанности в соответствии с действующим законодательством.</li>
      </ul>
      </section>
      <section id="4" ref={section4Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">4. ЧЛЕНСТВО В АССОЦИАЦИИ</div>
      <p>4.1. Членство. Порядок приема и выхода из членов.</p>
      <p>4.1.1. Членство в Ассоциации является добровольным.</p>
      <p>4.1.2. Учредители Ассоциации являются ее членами с момента создания и имеют соответствующие права и обязанности. Членами Ассоциации могут быть физические и юридические лица.</p>
      <p>4.1.3. Члены Ассоциации имеют равные права и несут равные обязанности.</p>
      <p>4.1.4. Члены Ассоциации сохраняют свою самостоятельность и права.</p>
      <p>4.1.5. Члены Ассоциации имеют право:
        <br />— безвозмездно пользоваться услугами Ассоциации;
        <br />— участвовать в работе и в управлении делами Ассоциации;
        <br />— по своему усмотрению выйти из Ассоциации в любое время;
        <br />— получать информацию о мероприятиях Ассоциации и участвовать в них;
        <br />— вносить предложения в любые органы Ассоциации по вопросам, связанным с ее деятельностью;
        <br />— получать информацию о деятельности Ассоциации на основании письменного запроса, адресованного Председателю Правления Ассоциации. Последний обязан предоставить запрашиваемую информацию или выдать мотивированный письменный отказ в двухнедельный срок со дня получения названного вопроса;
        <br />— передавать имущество в собственность Ассоциации.</p>
        <p>4.1.6. Члены Ассоциации обязаны:
        <br />— соблюдать Устав Ассоциации;
        <br />— своевременно уплачивать вступительный и членские взносы в порядке и размере, установленном Общим собранием членов Ассоциации;
        <br />— во время своего членства в Ассоциации нести субсидиарную ответственность по обязательствам Ассоциации в размере своего взноса в имущество Ассоциации в порядке, предусмотренном Гражданским кодексом РФ (под взносом члена в имущество Ассоциации понимается внесенный им вступительный взнос);
        <br />— выполнять решения органов Ассоциации, принятые в рамках их компетенции;
        <br />— участвовать в деятельности Ассоциации;
        <br />— оказывать содействие укреплению имущественной базы Ассоциации;
        <br />— принимать участие в заседаниях Общего собрания членов Ассоциации.</p>
        <p>4.1.7. Принятие в члены Ассоциации.</p>
        <p>Ассоциация открыта для вступления новых членов. Членами Ассоциации могут быть граждане из России, Украины, Азербайджана, Армении, Беларуси, Казахстана, Киргизии, Молдовы, Таджикистана, Туркменистана, Узбекистана и Грузии, не моложе 18 лет, которые продемонстрировали исключительно высокие достижения в области искусства.</p>
        <p>Ассоциация предъявляет к своим членам высокие требования и принимает в свои ряды только тех профессионалов, которые:</p>
        <ul className='goalsList'>
        <li>Имеют на своем счету национальные или международные награды, премии или другие виды признания, подтверждающие их выдающиеся достижения в своей творческой области.</li>
        <li>Продемонстрировали высокие достижения в искусстве, признанные авторитетными экспертами на национальном или международном уровне.</li>
        <li>Имеют активное участие в национальных и международных конкурсах, фестивалях, выставках или других мероприятиях высокого уровня, посвященных искусству.</li>
        <li>Получили поддержку и рекомендации от действующих членов Ассоциации или признанных экспертов в своей области.</li>
        </ul>
        <p>Для вступления в ассоциацию необходимо:</p>
        <p>4.1.7.1 Подача заявления о вступлении в Международную Ассоциацию Деятелей Искусств.</p>
        <p>4.1.7.2. Три рекомендации от действующих членов Ассоциации или признанных международных или национальных экспертов в области искусства.</p>
        <p>4.1.7.3. Подтверждение участия в не менее трех крупных национальных или международных мероприятиях (мастер-классы, концерты, фестивали, конкурсы).</p>
        <p>4.1.7.4. Подтверждение выдающихся творческих достижений на национальном или международном уровне (награды, призы, дипломы и другие виды признания).</p>
        <p>4.1.7.5. Портфолио работ (не менее десяти), демонстрирующих исключительный творческий уровень.</p>
        <p>4.1.7.6. Подтверждение публикаций:</p>
        <ul className='goalsList'>
        <li>Статьи о выдающейся деятельности артиста, опубликованные в крупных или известных изданиях.</li>
        <p>либо</p>
        <li>Научные статьи, написанные артистом, опубликованные в профессиональных или крупных специализированных изданиях, посвящённых искусству.</li>
        </ul>
        <p>4.1.7.7. Автобиография с описанием профессиональных достижений и наград.</p>
        <p>4.1.7.8. Членство в Ассоциации предоставляется только лицам с исключительными заслугами и признанием на международном или национальном уровне. Членство в Ассоциации является личным и неотчуждаемым. Последствия прекращения членства в Ассоциации устанавливаются законом и уставом Ассоциации.</p>
        <p>4.1.7.9. Членство в Ассоциации неотчуждаемо. Последствия прекращения членства в Ассоциации устанавливаются законом и уставом Ассоциации.</p>
        <p>4.1.8. Выход из членов Ассоциации.</p>
        <p>4.1.8.1. Член Ассоциации вправе по своему усмотрению выйти из Ассоциации в любое время. В этом случае член Ассоциации несет субсидиарную ответственность по ее обязательствам пропорционально своему взносу в течение двух лет с момента выхода (под взносом члена в имущество Ассоциации понимается внесенный им вступительный взнос). Размер ответственности вышедшего члена Ассоциации по обязательствам Ассоциации не может превышать размер вступительного взноса, внесенного этим членом Ассоциации.</p>
        <p>4.1.8.2. Выход из членов Ассоциации осуществляется по предоставляемому в Общее собрание членов Ассоциации через Председателя Правления Ассоциации письменному заявлению, в котором указываются причины выхода.</p>
        <p>4.1.8.3. Член Ассоциации считается вышедшим из Ассоциации с даты, указанной в заявлении, которая не может быть ранее дня подачи заявления.</p>
        <p>4.1.9. Исключение из членов Ассоциации.</p>
        <p>4.1.9.1. Член Ассоциации может быть исключен из ее состава по решению Общего собрания членов Ассоциации в случае неоднократного невыполнения требований Устава Ассоциации, неуплаты в установленный Уставом срок вступительного взноса, неоднократной неуплаты членских взносов, осуществления действий, либо бездействий, препятствующих работе Ассоциации.</p>
        <p>4.1.9.2. Для принятия решения об исключении из членов Ассоциации, Председатель Правления Ассоциации должен созвать внеочередное заседание Общего собрания членов Ассоциации.</p>
        <p>4.1.9.3. Решение об исключении из членов Ассоциации принимается остающимися членами Ассоциации</p>
        <p>4.1.9.4. Инициатором исключения члена из Ассоциации может быть любой член Ассоциации.</p>
        <p>4.1.9.5. Член Ассоциации считается исключенным из Ассоциации со дня, следующего за днем принятия Общим собранием членов Ассоциации решения об исключении.</p>
        <p>4.1.9.6. В случае исключения из Ассоциации член Ассоциации несет субсидиарную ответственность по ее обязательствам пропорционально своему взносу в течение двух лет с момента исключения (под взносом члена в имущество Ассоциации понимается внесенный им вступительный взнос). Размер ответственности исключенного члена Ассоциации по обязательствам Ассоциации не может превышать размер вступительного взноса, внесенного этим членом Ассоциации.</p>
      </section>
      <section id="5" ref={section5Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">5. УПРАВЛЕНИЕ АССОЦИАЦИЕЙ</div>
      <p>5.1. Высшим органом управления Ассоциацией является Общее собрание членов Ассоциации, далее – Общее собрание.</p>
      <p>5.2. Постоянно действующим коллегиальным органом управления Ассоциации в период между Общими собраниями является Правление Ассоциации.</p>
      <p>5.3. Единоличным исполнительным органом, осуществляющим текущее руководство деятельности, является Председатель Правления.</p>
      <p>5.3. Органом, осуществляющим контроль за финансово-хозяйственной деятельностью Ассоциации, является Ревизионная комиссия (Ревизор).</p>
      </section>
      <section id="6" ref={section6Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">6. ОБЩЕЕ СОБРАНИЕ ЧЛЕНОВ АССОЦИАЦИИ</div>
      <p>6.1. Общее собрание созывается по мере необходимости, но не реже одного раза в год. Общее собрания правомочно, если на нем присутствуют более половины членов Ассоциации.</p>
      <p>6.2. Общее собрание правомочно принимать решения по любым вопросам деятельности Ассоциации. К исключительной компетенции Общего собрания Ассоциации относятся:</p>
      <ul className='goalsList'>
        <li>пределение приоритетных направлений деятельности Ассоциации, принципов образования и использования ее имущества;</li>
        <li>утверждение и изменение устава Ассоциации;</li>
        <li>определение порядка приема в состав членов Ассоциации и исключения из числа ее членов;</li>
        <li>образование других органов Ассоциации и досрочное прекращение их полномочий;</li>
        <li>утверждение годовых отчетов и бухгалтерской (финансовой) отчетности Ассоциации;</li>
        <li>принятие решений о создании Ассоциаций других юридических лиц, об участии Ассоциации в других юридических лицах, о создании филиалов и об открытии представительств Ассоциации;</li>
        <li>принятие решений о реорганизации и ликвидации Ассоциации, о назначении ликвидационной комиссии (ликвидатора) и об утверждение ликвидационного баланса;</li>
        <li>избрание Ревизионной комиссии (Ревизора) и назначение аудиторской организации или индивидуального аудитора Ассоциации;</li>
        <li>принятие решений о порядке определения размера и способа уплаты членских взносов, о дополнительных имущественных взносов членов Ассоциации и ее имущество.</li></ul>
      <p>К компетенции Общего собрания могут быть отнесены иные вопросы деятельности Ассоциации. Вопросы, отнесенные к исключительной компетенции Общего собрания Ассоциации, не могут быть преданы для решения другим органам Ассоциации.</p>
      <p>6.3. Решение по вопросам, относящимся к исключительной компетенции Общего собрания, принимаются квалифицированным большинством не менее чем в 2/3 голосов членов Ассоциации, присутствующих на Общем собрании, кроме вопросов о реорганизации и ликвидации Ассоциации, которые принимаются единогласно всеми членами Ассоциации. Решения по другим вопросам, относящимся к деятельности Ассоциации, принимаются простым большинством голосов членов Ассоциации, присутствующих на Общем собрании.</p>
      <p>6.4. Очередное (ежегодное) Общее собрание членов Ассоциации созывается не позднее 3 месяцев по окончании финансового года. Общее собрание, организуемые в промежутках между ежегодными собраниями, являются внеочередными.</p>
      <p>6.5. Внеочередные Общие собрания членов Ассоциации созываются по мере необходимости, а также по решению Правления, по решению Председателя Правления, по требованию Ревизионной комиссии (Ревизора) или по требованию не менее 1/3 членов Ассоциации.</p>
      <p>6.6. Общее собрание членов самостоятельно определяет порядок работы и принимает решения по вопросам деятельности Ассоциации, руководствуясь положениями Устава и нормами действующего законодательства Российской Федерации.</p>
      <p>6.7. Общее собрание членов правомочно принимать решения, если в его работе принимают участие более половины членов Ассоциации. В случае невозможности обеспечения кворума для проведения Общего собрания членов заседание Общего собрания членов переносится, но не более чем на неделею. Каждый член Ассоциации при голосовании на заседании Общего собрания членов обладает одним голосом.</p>
      <p>6.8. Общее собрание членов созывается на каждое заседание Председателем Правления в письменной форме с указанием даты, места и повестки дня. Приглашение на заседание производится на менее чем за неделю до даты его проведения. В экстренных случаях, допускается уведомление о созыве Общего собрания членов без соблюдения указанного срока.</p>
      <p>6.9. Работу Общего собрания членов организует Председатель Общего собрания членов, который избирается из числа членов Общего Собрания членов большинством голосов.</p>
      <p>6.10. С целью ведения протокола Общего собрания членов из числа членов Общего собрания членов, большинством голосов избирается секретарь Общего собрания членов.</p>
      <p>6.11. Председатель Общего собрания членов подписывает протоколы заседаний Общего собрания членов и иные акты, принятые Общим собранием членов.</p>
      <p>6.12. Председатель Правления Ассоциации не может быть избран Председателем или секретарем Общего собрания членов.</p>
      <p>6.13. О решениях Общего собрания членов составляются протоколы, которые подписываются Председателем и секретарем Общего собрания членов, и рассылаются всем членам Общего собрания.</p>
      
      </section>
      <section id="7" ref={section7Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">7. ПРАВЛЕНИЕ АССОЦИАЦИИ</div>
      <p>7.1. Постоянно действующим коллегиальным органом управления Ассоциации в период между Общими собраниями является Правление Ассоциации.</p>
      <p>7.2. Правление Ассоциации возглавляется Председателем Правления Ассоциации.</p>
      <p>7.3. Правление Ассоциации в количестве 7 членов избирается Общим собранием сроком на 5 (пять) лет.</p>
      <p>7.4. Проведение заседаний Правления Ассоциации организует Председатель Правления Ассоциации.</p>
      <p>7.5. Правление Ассоциации правомочно при присутствии более половины его членов. Решение принимается простым большинством голосов от числа присутствующих на заседании Правления Ассоциации членов Правления Ассоциации и вступает в силу немедленно, если иное не указано в самом решении.</p>
      <p>7.6. Заседания Правления Ассоциации проводятся по мере необходимости, но не реже одного раза в три месяца. Протоколы заседания Правления Ассоциации подписываются Председателем Правления Ассоциации.</p>
      <p>7.7. Внеочередные заседания Правления Ассоциации могут быть созваны по требованию Ревизионной комиссии (Ревизора), 1/3 (одной трети) членов Правления Ассоциации или по инициативе Председателя Правления Ассоциации.</p>
      <p>7.8. Правление Ассоциации:
      <br />— безвозмездно пользоваться услугами Ассоциации;
        <br />— принимает решение о созыве Общего собрания членов Ассоциации, определяет вопросы повестки дня, обеспечивает выполнение решений Общего собрания членов Ассоциации;
        <br />— определяет основные направления и формы практической деятельности Ассоциации по реализации решений Общего собрания и уставных целей Ассоциации. Утверждает отчеты Председателя Правления Ассоциации о выполнении решений Общего собрания и Правления;
        <br />— утверждает бюджеты Ассоциации и отчеты об их исполнении, включая статьи доходов и расходов, связанные с осуществлением предпринимательской деятельности Ассоциации;
        <br />— по предложению Председателя Правления Ассоциации утверждает казначея/бухгалтера Ассоциации;
        <br />— принимает решения по вопросам деятельности Ассоциации, вносимым на рассмотрение Председателем Правления Ассоциации и Ревизионной комиссией (Ревизором) Ассоциации. Утверждает по представлению Председателя Правления Ассоциации планы деятельности Ассоциации и отчеты об их исполнении;
        <br />— утверждает годовой отчет и годовой бухгалтерский баланс;
        <br />— определяет перечень льгот, предоставляемых членам Ассоциации;
        <br />— анализирует и контролирует ход выполнения решений Общего собрания и Председателя Правления Ассоциации;
        <br />— утверждает штатное расписание;
        <br />— решает иные вопросы, касающиеся деятельности Ассоциации и не отнесенные к исключительной компетенции Общего собрания Ассоциации.
        </p>
      </section>
      <section id="8" ref={section8Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">8. ПРЕДСЕДАТЕЛЬ ПРАВЛЕНИЯ АССОЦИАЦИИ</div>
      <p>8.1. Председатель Правления Ассоциации избирается на Общем собрании сроком на 5 (пять) лет. Решение об избрании принимается квалифицированным большинством 2/3 (две третьей) голосов от присутствующих на Общем собрании членов Ассоциации.</p>
      <p>8.2. Председатель Правления Ассоциации осуществляет свои полномочия на постоянной основе до момента отзыва по собственному желанию или по решению Общего собрания членов Ассоциации.</p>
      <p>8.3. Председатель Правления Ассоциации подотчетен Общему собранию, несет ответственность перед Ассоциацией за результаты и законность своей деятельности и деятельности Правления Ассоциации.</p>
      <p>8.4. Председатель Правления Ассоциации обязан предоставлять Общему собранию членов Ассоциации, Ревизионной комиссии (Ревизору) Ассоциации любую информацию о деятельности Ассоциации по первому их требованию.</p>
      <p>8.5. Председатель Правления Ассоциации:
        <br />— без доверенности действует от имени Ассоциации и представляет её интересы непосредственно в отношениях с третьими лицами и государством;
        <br />— организует заседания Правления Ассоциации;я, обеспечивает выполнение решений Общего собрания членов Ассоциации;
        <br />— организует исполнение решений Общего собрания членов Ассоциации, Правления Ассоциации;ешений Общего собрания и уставных целей Ассоциации. Утверждает отчеты Председателя Правления Ассоциации о выполнении решений Общего собрания и Правления;
        <br />— вносит	на	утверждение	Правления	Ассоциации	проекты	планов	деятельности Ассоциации, а также отчеты об исполнении этих планов;связанные с осуществлением предпринимательской деятельности Ассоциации;
        <br />— согласовывает штатное расписание Ассоциации, определяет размеры и порядок оплаты труда сотрудников в пределах фонда оплаты труда, установленного бюджетом Ассоциации;
        <br />— распоряжается	имуществом	и	денежными	средствами	в	пределах	бюджетов, утвержденных Правлением Ассоциации; комиссией (Ревизором) Ассоциации. Утверждает по представлению Председателя Правления Ассоциации планы деятельности Ассоциации и отчеты об их исполнении;
        <br />— заключает и расторгает договоры и другие юридические акты;
        <br />— подписывает бухгалтерские и финансовые документы;
        <br />— открывает и закрывает счета в банках и других кредитных учреждениях;
        <br />— решает вопросы материально-технического снабжения и финансового обеспечения деятельности Ассоциации;
        <br />— принимает на работу и увольняет сотрудников Ассоциации, выносит решения о поощрениях и наложении взысканий;
        <br />—по согласованию с Правлением Ассоциации назначает Руководителей проектов, а также отстраняет их от возложенных обязанностей;
        <br />—определяет круг полномочий Руководителей проектов, а также иных сотрудников Ассоциации, утверждает должностные инструкции;
        <br />—обеспечивает ведение бухгалтерского и статистического учета и отчетности;
        <br />—несет ответственность за выполнение поручений Правления Ассоциации;
        <br />—решает в соответствии с действующим законодательством другие вопросы, касающиеся деятельности Ассоциации и не отнесенные к компетенции других органов Ассоциации.
      </p>
      <p>8.7. Во время отсутствия Председателя Правления Ассоциации или иной невозможности исполнять им своих обязанностей, полномочия Председателя Правления Ассоциации возлагаются на Заместителей Председателя Правления Ассоциации, избираемых из числа членов Правления Ассоциации Общим собранием членов Ассоциации сроком на 5 (пять) лет. Заместители Председателя Правления Ассоциации курируют закрепленные за ними направления деятельности Ассоциации.</p>
      </section>
      <section id="9" ref={section9Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">9. КОНТРОЛЬНО-РЕВИЗИОННЫЙ ОРГАН АССОЦИАЦИИ</div>
      <p>9.1. Контроль за финансово-хозяйственной деятельностью Ассоциации осуществляет Ревизионная комиссия (Ревизор).</p>
      <p>9.2. Полномочия и порядок осуществления деятельности Ревизионной комиссии (Ревизора) определяется Положением о Ревизионной комиссии (Ревизоре) утверждаемым Общим собранием.</p>
      <p>9.3. Ревизионная комиссия (Ревизор) избирается Общим собранием из числа членов Ассоциации сроком на 5 (пять) лет.</p>
      <p>9.4. Члены Ревизионной комиссии (Ревизор) не могут одновременно занимать должности в других органах Ассоциации.</p>
      <p>9.5. Ревизионная комиссия (Ревизор) осуществляет проверки финансово-хозяйственной деятельности Ассоциации не реже одного раза в год, а также в любое время по инициативе членов Ассоциации и/или Председателя Правления Ассоциации.</p>
      <p>9.6. Ревизионная комиссия (Ревизор) вправе требовать от должностных лиц Ассоциации предоставления всех необходимых документов и личных объяснений.</p>
      <p>9.7. Ревизионная комиссия (Ревизор) представляет результаты проверок Общему собранию после обсуждения их на заседании Правления Ассоциации.</p>
      </section>
      <section id="10" ref={section10Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">10. ИМУЩЕСТВО АССОЦИАЦИИ</div>
      <p>10.1. Ассоциация может иметь в собственности здания, сооружения, жилищный фонд, оборудование, инвентарь, денежные средства, ценные бумаги и иное имущество. Ассоциация может иметь земельные участки в собственности или на ином праве в соответствии с законодательством Российской Федерации.</p>
      <p>10.2. Источниками формирования имущества Ассоциации являются:</p>
      <ul className='goalsList'>
        <li>регулярные, в виде взносов, и единовременные поступления от членов Ассоциации;</li>
        <li>добровольные имущественные взносы и пожертвования, в том числе от российских и иностранных граждан, юридических лиц российских и международных организаций;</li>
        <li>доходы, получаемые по акциям, другим ценным бумагам и вкладам;</li>
        <li>доходы, получаемые от собственности Ассоциации;</li>
        <li>гранты от юридических и физических лиц;</li>
        <li>поступления	от	оказания	консультационно-информационных	услуг	любым заинтересованным сторонам по вопросам, входящим в сферу деятельности Ассоциации;</li>
        <li>прибыль учрежденных Ассоциацией хозяйственных обществ;</li>
        <li>другие, не запрещенные законом поступления.</li>
        <p>10.3. Имущество, переданное Ассоциации членами, приобретенное за счет собственных средств, переданное ей в виде добровольных взносов и пожертвований, принадлежит ей на праве собственности.</p>
        <p>10.4. Имущество и денежные средства Ассоциации используются в соответствии с уставными целями.</p>
      </ul>
      </section>
      <section id="11" ref={section11Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">11. УЧЕТ И ОТЧЕТНОСТЬ АССОЦИАЦИИ</div>
      <p>11.1. Ассоциация ведет бухгалтерский учет и статистическую отчетность в порядке, предусмотренном законодательством Российской Федерации. Ответственность за достоверность данных бухгалтерского и статистического учета несет Председатель Правления Ассоциации.</p>
      </section>
      <section id="12" ref={section12Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">12. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ И ДОПОЛНЕНИЙ В УСТАВ</div>
      <p>12.1. Изменения в Устав Ассоциации утверждаются Общим собранием членов Ассоциации.</p>
      <p>12.2.Изменения в Устав подлежат государственной регистрации.</p>
      <p>12.3.Государственная регистрация изменений в Устав Ассоциации осуществляется в соответствии с действующим законодательством РФ.</p>
      </section>
      <section id="13" ref={section13Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">13. ВНЕШНЕЭКОНОМИЧЕСКАЯ	ДЕЯТЕЛЬНОСТЬ	И	МЕЖДУНАРОДНЫЕ СВЯЗИ АССОЦИАЦИИ</div>
      <p>13.1. Ассоциация имеет право пользоваться кредитами российских и зарубежных банков.</p>
      <p>13.2. Ассоциация	может	вступать	в	международные	общественные	объединения, приобретать права и нести обязанности, соответствующие статусу этих международных объединений, поддерживать прямые международные контакты и связи, заключать соглашения с иностранными некоммерческими неправительственными объединениями.</p>
      </section>
      <section id="14" ref={section14Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">14. РЕОРГАНИЗАЦИЯ И ЛИКВИДАЦИЯ АССОЦИАЦИИ</div>
      <p>14.1. Реорганизация Ассоциации осуществляется в порядке, предусмотренном действующим законодательством РФ.</p>
      <p>14.2. Реорганизация Ассоциации может быть осуществлена в форме слияния, присоединения, разделения, выделения и преобразования.</p>
      <p>14.3. Реорганизация осуществляется по решению Общего собрания членов Ассоциации. Решение о преобразовании Ассоциации принимается всеми членами Ассоциации. Ассоциация может быть преобразована в общественную организацию, автономную некоммерческую организацию или фонд.</p>
      <p>14.4. Ассоциация считается реорганизованной с момента государственной регистрации вновь возникшей организации.</p>
      <p>14.5. Государственная регистрация вновь возникшей организации осуществляется в порядке, предусмотренном действующим законодательством РФ.</p>
      <p>14.6. Имущество Ассоциации переходит после ее реорганизации к вновь возникшим юридическим лицам в порядке, предусмотренном действующим законодательством РФ.</p>
      <p>14.7. Ликвидация Ассоциации осуществляется в порядке, предусмотренном действующим законодательством РФ.</p>
      <p>14.8. Решение о ликвидации Ассоциации принимается Общим собранием членов Ассоциации, либо судом.</p>
      <p>14.9. Общее собрание членов Ассоциации назначает ликвидационную комиссию и устанавливает порядок и сроки ликвидации.</p>
      <p>14.10. С момента назначения ликвидационной комиссии к ней переходят полномочия по управлению делами Ассоциации. Ликвидационная комиссия завершает текущие дела Ассоциации, от имени Ассоциации выступает в суде.</p>
      <p>14.11. Ликвидационная комиссия помещает в органах печати публикацию о ликвидации Ассоциации, порядке и сроке заявления требований ее кредиторами.</p>
      <p>14.12. По окончании срока для предъявления требований кредиторами ликвидационная комиссия составляет промежуточный ликвидационный баланс. Промежуточный ликвидационный баланс утверждается Общим собранием членов Ассоциации.</p>
      <p>14.13. В случае отсутствия единогласно принятого Общим собранием членов Ассоциации решения об утверждении промежуточного ликвидационного баланса, Общее собрание членов Ассоциации имеет право назначить независимую аудиторскую проверку за счет Ассоциации.</p>
      <p>14.14. После завершения расчетов с кредиторами ликвидационная комиссия составляет ликвидационный баланс, который утверждается Общим собранием членов Ассоциации.</p>
      <p>14.15. Оставшееся после удовлетворения требований кредиторов имущество направляется на цели, в интересах которых Ассоциация была создана и (или) на благотворительные цели.</p>
      <p>14.16. При завершении ликвидации Ассоциации документы постоянного хранения, имеющие научно-историческое значение, передаются на государственное хранение в соответствующие архивы, документы по личному составу (приказы, личные дела, карточки учета, лицевые счета и т.п.) предаются на хранение в архив, на территории которого находится Ассоциация. Передача и упорядочение документов осуществляется силами и за счет средств Ассоциации в соответствии с требованиями архивных органов.</p>
      </section>
      </div>
      </div>
      </div>
    );
  }

  export default Statute;
