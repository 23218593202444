import React, { useEffect } from 'react';

function ArticlePage10() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <div className="xContent p-box py-6">
        <div className='xContent-wrapper'>
        <h1 className="title is-font-title-regular has-text-centered has-text-var-blue">Опубликована партитура оперы Эдуарда Артемьева «Преступление и наказание»</h1>
        <div className="content">
        <p className='has-text-centered p-style p-1'>Тема: партитура оперы Эдуарда Артемьева «Преступление и наказание»</p>
        <p>Вышла в свет партитура оперы Эдуарда Артемьева «Преступление и наказание». Два ее тома с приложением на CD опубликованы издательством «Композитор • Санкт-Петербург» при участии Российского музыкального союза и Национального фонда поддержки правообладателей.</p>
        <p>«Преступление и наказание» – опера в двух актах композитора Эдуарда Артемьева по мотивам одноименного романа Федора Достоевского. Авторами либретто выступили режиссеры Андрей Кончаловский, Марк Розовский и поэт Юрий Ряшенцев.</p>
        <p>Масштабное произведение имеет непростую судьбу: Эдуард Николаевич начал писать его в 1979 году и сумел полностью закончить и представить на суд слушателей только к своему 70-летнему юбилею. В 2007 году издание двойного альбома с оперой стало одним из самых ярких и заметных событий в области отечественного музыкального искусства. В 2016 году премьера рок-оперы прошла в Московском театре мюзикла.</p>
        <p>Опубликованное сочинение сложно уместить в рамки одного жанра: оно объединяет в себе традиции академической оперы и звучание рок-музыки, возможности симфонического оркестра и новые тембры, открытые лишь в XXI веке. В произведении органично переплетены народные голоса, оперное пение, старинный русский вальс (романс), православные песнопения, интонации джаза, русский шансон… Разнообразен и инструментальный состав: помимо большого симфонического и народного оркестров, в рок-опере задействованы бас-гитара, акустический рояль, арфа, синтезаторы. При этом «Преступление и наказание» представляет собой единый симфонический организм с динамично выстроенной драматургией.</p>
        <p>Партитура произведения будет на безвозмездной основе передана ведущим российским и зарубежным музыкальным вузам и библиотекам. Так, издание станет доступно многим музыкантам, которые вновь и вновь будут радовать слушателей исполнением оперы и ее фрагментов.</p>
        </div>
      </div>
      </div>
    );
  }

  export default ArticlePage10;
