import React, { useEffect } from 'react';

function ArticlePage01() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="xContent p-box py-6">
      <div className='xContent-wrapper'>
      <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">Санкт-Петербургский оркестр саксофонов выступил в Александро-Невской лавре</h1>
      <div className="content">
      <p>23 декабря 2023 года Санкт-Петербургский оркестр саксофонов под управлением Игоря Кузнецова дал предновогодний концерт в Свято-Троицкой Александро-Невской лавре. Оркестр входит в состав Российского музыкального союза и в рамках своей творческой и концертной деятельности популяризирует произведения отечественных композиторов.</p>
      <p>Санкт-Петербургский оркестр саксофонов появился в Северной столице России в 2019 году и стал первым за всю историю города духовым оркестром, в состав которого входят только саксофоны: сопрано, альт, тенор, баритон. Дебют необычного оркестра состоялся на Международной неделе консерваторий в октябре 2019 года. В репертуаре коллектива инструменталистов — переложения камерных и симфонических произведений П.И. Чайковского, С.В. Рахманинова, Г.В. Свиридова, Д.Д. Шостаковича, Дж. Гершвина, А.И. Хачатуряна и других композиторов. Автором многих переложений является главный дирижер и основатель коллектива — Игорь Кузнецов.</p>
      <p>У Санкт-Петербургского оркестра саксофонов много поклонников и постоянных слушателей. Один из них — композитор, аранжировщик, саксофонист, заслуженный артист России, заслуженный деятель искусств России Владимир Петрович Пресняков. Маэстро поддерживает музыкантов и иногда дает им свои наставления. На концерте была исполнена его пьеса «Innamorato».</p>
      <p>«Думаю, что само существование такого оркестра, состоящего только из саксофонов, уникально! К тому же, оркестра, играющего не джаз, а классическую и легкую музыку. Все знают, что саксофон — это инструмент, являющийся основным, лидирующим инструментом джаза, в классике его роль довольно незначительна. Коллективом руководит великолепный композитор, аранжировщик и саксофонист Игорь Кузнецов. Играют в оркестре прекрасные музыканты и, что важно, энтузиасты, которые собираются и творят не за деньги, а из любви к тому, чем они занимаются! И, конечно, хочу отметить огромную, бескорыстную организаторскую деятельность мамы руководителя и одного из музыкантов оркестра, Ирины Анчеловны Кузнецовой, деятельность, которую трудно переоценить! В заключение хочу сказать, что Санкт-Петербург может гордиться тем, что именно в этом городе есть такой славный коллектив!»</p>
      <p>Создание коллектива инициировал выпускник Санкт-Петербургского государственного института культуры, лауреат международных конкурсов, член Российского музыкального союза, приглашенный солист заслуженного коллектива России Академического симфонического оркестра Санкт-Петербургской филармонии, Михайловского театра, духового оркестра Мариинского театра, Государственной академической капеллы Санкт-Петербурга Игорь Кузнецов.</p>
      <p>В состав объединения вошли выпускники Санкт-Петербургской государственной консерватории им. Н.А. Римского-Корсакова, Санкт-Петербургского государственного института культуры, преподаватели высших музыкальных учебных заведений и детских школ искусств Санкт-Петербурга. В ноябре 2019 года оркестр был приглашен на церемонию награждения Всероссийского конкурса «Современное наследие России», который проходил в рамках VIII Санкт-Петербургского культурного международного форума.</p>
      <p>В преддверии концерта в Александро-Невской лавре дирижер, художественный руководитель Санкт-Петербургского оркестра саксофонов Игорь Кузнецов и солистка Екатерина Кузнецова побывали в гостях у программы «Утро в Петербурге», где рассказали, как родилась идея создания уникального оркестра.</p>
      <p>«Изначально был квартет, для которого я начинал писать переложения. Чем больше я писал, тем больше понимал, что мне не хватает голосов. Постепенно я добавлял саксофон, второй саксофон, потом ударные и рояль. Когда решил свое переложение воплотить в жизнь, то начал обзванивать знакомых саксофонистов, и они с большим энтузиазмом откликнулись на эту идею. Наиболее часто используются четыре вида саксофонов: сопрано, альт, тенор и баритон. Все эти саксофоны участвуют в моем оркестре. Существуют более редко используемые инструменты, как бас-саксофон. На один из последних концертов мы привозили бас-саксофон из Москвы вместе с бас-саксофонистом. Такое явление достаточно редкое, и это было первое выступление оркестра в таком составе. В основном дирижирую, но порой публика настойчиво требует, и я беру в руки инструмент и солирую. Мне повезло, потому как у меня в оркестре одни из самых лучших саксофонистов в городе. Благодаря им получается то качество коллектива, которое есть», — поделился Игорь Кузнецов.</p>
      <p>Солистка Санкт-Петербургского оркестра саксофонов Екатерина Кузнецова родилась в Санкт-Петербурге. Девушка решила пойти по стопам брата и тоже связала свою жизнь с музыкой. Обучалась в Средней специальной музыкальной школе при СПбГК им. Н.А. Римского-Корсакова (класс профессора Андрея Большиянова). Сейчас является студенткой СПбГК им. Н.А. Римского-Корсакова (класс Никиты Лютикова). Лауреат юношеских конкурсов, участвовала в образовательной программе ОЦ «Сириус» (2018), Академии камерной музыки в Мюррене (Швейцария, 2018), арт-кластере «Таврида» (2021), Академии камерной музыки в Оксенхаузене (Германия, 2022), образовательной программе Дягилевского фестиваля (Пермь, 2022), концертной программе Дягилевского фестиваля (Пермь, 2023). Стипендиат фонда Бруно Фрея (Германия).</p>
      <p>«В какой-то момент Игорю пришла идея приглашать разных солистов. Я играю на кларнете, это родственный инструмент. Для меня это был новый и прекрасный опыт», — рассказала Екатерина Кузнецова в студии программы «Утро в Петербурге».</p>
      <p>В концертной программе 23 декабря она исполнила «Éljen a Magyar!» Иоганна Штрауса II. Также со сцены прозвучали сюита из балета «Щелкунчик» Петра Чайковского, джазовая сюита №1 Дмитрия Шостаковича, вальс Микаэла Таривердиева из кинофильма «Ирония судьбы».</p>
      <p>После концерта зрители охотно делились своими впечатлениями, особо отмечая исполнение Игорем Кузнецовым «Innamorato» Владимира Петровича Преснякова. «Хочу выразить огромную благодарность Санкт-Петербургскому оркестру саксофонов за прекраснейший рождественский вечер, который музыканты нам подарили. Было неимоверно красиво, звучно, объемно, приятно. Самое интересное — это премьера «Innamorato». Для меня автор открылся с другой стороны», — подчеркнула одна из слушательниц.</p>
      <p>«Я рад, что соприкоснулся с творчеством прекрасного коллектива! Думаю, оркестр достоин звучать не только на петербургской сцене, но и в Москве и по всей нашей стране!»</p>
      </div>
      </div>
    </div>
  );
}

export default ArticlePage01;
