import React, { useEffect } from 'react';
import OrderImg from '../images/order.png'
import { Helmet } from 'react-helmet-async';

function Order() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="xContent p-box py-6">
          <Helmet>
        <title>Орден "Почетный деятель искусств"</title>
        <meta
        name="description"
        content='Орден "Почетный деятель искусств" является высшей наградой, которую вручает Международная Ассоциация Деятелей Искусств.'
        />
        <link rel="canonical" href="https://arts-association.com/order" />
      </Helmet>
      <div className='xContent-wrapper'>
              <div className="page-title">Орден "Почетный деятель искусств"</div>
        <div className="content">
        <div class="block">
    <div class="orderImage"><img src={OrderImg} alt="Order" /></div>
    </div>
    <p>Орден "Почетный деятель искусств" является высшей наградой, которую вручает Международная Ассоциация Деятелей Искусств. Он присуждается за выдающиеся достижения в области культуры и искусства, а также за международное признание талантов и вклада в культурное наследие.</p>
    <p>Награда символизирует не только личное мастерство и высокий профессиональный уровень, но и важный вклад в мировую культурную жизнь. Кавалеры Ордена "Почетный деятель искусств" являются деятелями, чья работа имела международный резонанс, обогатила культурное разнообразие и способствовала культурному диалогу и сотрудничеству между странами.</p>
    <p>Награда вручается ежегодно только пятерым представителям из стран СНГ, включая Россию, Украину, Азербайджан, Армению, Беларусь, Казахстан, Киргизию, Молдову, Таджикистан, Туркменистан, Узбекистан и Грузию. Это знаковое событие, подчеркивающее важность и значимость взаимоотношений в сфере искусств в регионе. Церемония награждения собирает элиту культурного мира СНГ, позволяя обмениваться идеями, опытом и создавать новые творческие связи.</p>
    <p>Процесс отбора кавалеров ордена строг и прозрачен. Кандидаты оцениваются по следующим критериям:</p>
    <ol className="goalsList">
      <li><b>Инновационный вклад в искусство:</b> развитие новых техник, жанров или стилей.</li>
      <li><b>Международное признание: </b> награды, публикации, выставки за пределами своей страны.</li>
      <li><b>Образовательная деятельность:</b> вклад в развитие и обучение следующих поколений деятелей искусства.</li>
      <li><b>Публичное признание:</b> широкая известность и репутация среди широкой общественности.</li>
      <li><b>Культурное влияние:</b> влияние на культурные тенденции и общественное мнение.</li>
    </ol>
      <p>Совет Ассоциации, состоящий из выдающихся деятелей культуры и искусства, проводит тщательный отбор кандидатов. Предложения о номинациях принимаются от членов Ассоциации и анализируются по множеству параметров, после чего Совет Ассоциации голосует за присвоение награды.</p>
      <p>Церемония награждения — это торжественное событие, в ходе которого лауреаты получают награды и сертификаты, а также признание своих достижений международным сообществом. Этот момент не только отмечает праздник таланта и профессионализма, но также служит важным средством для развития и укрепления культурного сотрудничества в странах СНГ и за их пределами.</p>
      <p>Орден "Почетный деятель искусств" является одной из самых престижных наград в области искусства, символизируя наивысшее признание достижений и вклада в культуру и искусство на международном уровне. Получение этого ордена для деятелей искусств не просто мечта, но и подтверждение их несомненного таланта, неустанного труда и значительного влияния на развитие культурного пространства. </p>
      <p>Вот несколько аспектов, подчеркивающих значимость этой награды:</p>
      <p><b>Маркер профессионального мастерства и успеха</b></p>
      <p>Для художников, музыкантов, писателей, звукорежиссеров, хореографов и других творческих личностей получение Ордена "Почетный деятель искусств" служит неопровержимым свидетельством их профессионализма и мастерства. Это признание приходит от коллег по цеху и авторитетных экспертов, что делает его особенно значимым.</p>
      <p><b>Влияние на карьеру и репутацию</b></p>
      <p>Награждение орденом часто оказывает существенное влияние на дальнейшую карьеру лауреата, открывая новые горизонты возможностей: от приглашений к участию в престижных проектах до предложений о сотрудничестве от ведущих культурных институтов. Репутация награжденного растет не только на родине, но и за рубежом.</p>
      <p><b>Укрепление международных связей</b></p>
      <p>Орден способствует укреплению международных культурных связей, поскольку его лауреаты являются деятелями искусства, чье творчество признано за пределами их страны. Это отражает не только их личные достижения, но и способствует продвижению культуры их народа на мировой арене.</p>
      <p><b>Вдохновение для будущих поколений</b></p>
      <p>Получение ордена служит вдохновением и ориентиром для молодых талантов, стремящихся к совершенству в своем искусстве. Оно демонстрирует, что творческий поиск, упорный труд и преданность искусству могут быть высоко оценены на самом высоком уровне.</p>
      <p><b>Признание значимости искусства</b></p>
      <p>Вручение Ордена "Почетный деятель искусств" подчеркивает важность искусства для общества, его способность влиять на мировоззрение, эмоциональное состояние и духовное развитие человека. Это признание того, что искусство является неотъемлемой частью человеческой цивилизации.</p>
      <p><b>Символ бессмертия творчества</b></p>
      <p>Для многих деятелей искусств получение такой награды является символом того, что их творчество и вклад в культуру будут помнить и цен ить поколения. Орден становится знаком бессмертия их искусства, гарантией того, что их труды будут вдохновлять, учить и радовать не одно поколение ценителей искусства. Это создаёт осознание того, что их вклад в культуру останется живым и после них, став частью общечеловеческого наследия.</p>
      <p><b>Эмоциональное и духовное признание</b></p>
      <p>За частую материальные награды и признание могут иметь временное значение, но Орден "Почетный деятель искусств" затрагивает глубинные струны души деятеля искусства. Он символизирует понимание и ценность их внутреннего мира, их страстей и мечтаний, их борьбы и побед в процессе творческого самовыражения. Для многих это духовное признание является наивысшей формой награды.</p>
      <p><b>Стимул к дальнейшему развитию</b></p>
      <p>Для лауреатов ордена это не конечная цель, а новый старт. Награда служит мощным стимулом к дальнейшему развитию, экспериментам и творческому поиску. Она подтверждает, что выбранный путь верен и вдохновляет на новые свершения в искусстве.</p>
      <p><b>Социальная ответственность и влияние</b></p>
      <p>Получение Ордена "Почетный деятель искусств" также накладывает на лауреатов определённую социальную ответственность. Они становятся образцами для подражания, их мнения и взгляды слушают, а значит, они могут влиять на общественное сознание, способствуя распространению идей гуманизма, мира и красоты через искусство.</p>
      <p><b>Усиление культурного диалога</b></p>
      <p>Наконец, Орден "Почетный деятель искусств" способствует усилению культурного диалога между разными странами и народами. Лауреаты часто участвуют в международных проектах и фестивалях, становясь посредниками между культурами и способствуя взаимному обогащению и пониманию.</p>
      <p>Орден "Почетный деятель искусств" — это не просто награда, это знак высокого общественного признания, символ неразрывной связи между прошлым, настоящим и будущим в искусстве, а также маяк для будущих поколений деятелей искусств, указывающий путь к вершинам творчества и человеческого духа.</p>

      </div>
      </div>
    </div>
  );
}

export default Order;
