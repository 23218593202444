import ArticlePage22Img from '../../images/articlepage22.png'
import ArticlePage22n2Img from '../../images/articlepage22n2.png'
import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';

function ArticlePage22() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <div className="xContent p-box py-6">
        <Helmet>
        <title>Микрофон Подо Льдом: Прорыв Александра Курасова в Технике Звукозаписи Alexander Kurasov</title>
        <meta
        name="description"
        content='Уникальная модель микрофона, размещенного в верхнем слое льда, позволяет минимизировать контакт с оборудованием и точно передавать звуки во время шоу. Alexander Kurasov'
        />
        <link rel="canonical" href="https://arts-association.com/article22"/>
      </Helmet>

      <div className='xContent-wrapper'>
      <div className="content p-20">
      <p className='has-text-left wysiwyg'>30 сентября 2020 года</p>
      <p className='has-text-left wysiwyg p-1'>Автор статьи: Леонид Аксенов</p>
        <div className="title p-1">Микрофон Подо Льдом: Прорыв Александра Курасова в Технике Звукозаписи</div>
          <div class="block">
    <div class="articleImage700"><img src={ArticlePage22Img} alt="Order" /></div>
    </div>
        <p>21 сентября 2020 года стал значимой датой в истории звукорежиссуры. В этот день Александр Курасов, главный звукорежиссер общероссийского федерального телеканала "Первый канал" и звукорежиссер телеканала "Матч ТВ", получил патент на полезную модель "Скрытый петличный микрофон в ледовом покрытии" от Федеральной службы по интеллектуальной собственности Роспатент.</p>
        <p>Этот прорыв в области звукозаписи открывает новые горизонты для телевизионных съемок мероприятий на ледовых аренах. Уникальная модель микрофона, размещенного в верхнем слое льда, позволяет минимизировать контакт с оборудованием и точно передавать звуки во время шоу и спортивных состязаний. Это изобретение не только облегчает технические аспекты трансляций, но и существенно повышает качество и глубину звучания, придавая зрителю ощущение полного погружения в атмосферу событий на льду.</p>
        <div class="block">
    <div class="articleImage700"><img src={ArticlePage22n2Img} alt="Order" /></div>
    </div>
        <p>Патент Курасова уже вызвал интерес среди ведущих специалистов в области телевещания по всему миру, что подтверждается подписанием лицензионного контракта с крупной украинской телекомпанией "Украина" для использования в ледовых шоу. Кроме того, "Первый канал" планирует применение этой технологии в популярном телешоу "Ледниковый период -7".</p>
        <p>Международное признание и востребованность модели "Скрытый петличный микрофон" отражает ее неоценимую значимость для звукорежиссеров. Теперь, благодаря этому открытию, профессионалы всего мира смогут достичь нового уровня качества звука, что бесспорно обогатит зрительский опыт и повысит стандарты телевизионных продукций на ледовых аренах.</p>
        </div>
        </div>
      </div>
    );
  }

  export default ArticlePage22;
