import React, { useEffect } from 'react';


function ArticlePage08() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <div className="xContent p-box py-6">
        <div className='xContent-wrapper'>
        <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">Игорь Бутман выступит на Skolkovo Jazz Science 2019</h1>
        <div className="content">
        <p>Игорь Бутман и Московский джазовый оркестр под его управлением вновь станут хедлайнерами музыкально-научного фестиваля Skolkovo Jazz Science, который состоится 24 августа в инновационном центре «Сколково».</p>
        <p>Совместно с виртуозным пианистом и вокалистом Олегом Аккуратовым музыканты представят специальную программу, посвященную 20-летию коллектива.</p>
        <p>Зарубежным гостем фестиваля станет Чайна Мозес, американо-французская звезда мирового уровня. Пластинка певицы Crazy Blues в 2013 году вошла в топ-100 лучших альбомов года по версии журнала Times. В мероприятии также примет участие молодая московская группа Brevis Brass Band. Именно эта команда вывела брасс-культуру в нашей стране на новый уровень, сделав ее по-настоящему популярной. В репертуаре ансамбля — оригинальные кавер-версии современных хитов и легендарных песен: Billie Jean, Seven Nation Army и Song 2.</p>
        <p>В рамках фестиваля также пройдут научные мастер-классы по аэродинамике, нейротехнологиям, роботостроению, различные познавательные дискуссии и лекции.</p>

        </div>
        </div>
      </div>
    );
  }

  export default ArticlePage08;
